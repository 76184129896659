import {
    Component,
    ChangeDetectionStrategy,
    OnDestroy,
    OnInit,
} from '@angular/core';

// language list
import { SplashScreenService } from './_metronic/partials/layout/splash-screen/splash-screen.service';
import { Router, NavigationEnd } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { TableExtendedService } from './_metronic/shared/crud-table';
import { TranslateService } from '@ngx-translate/core';
import { InstancesService } from './services/instances.service';
import { AccountCreationComponent } from './pages/components/account-creation/account-creation.component';
import { MatDialog } from '@angular/material/dialog';
import { InvitesService } from './services/invites.service';
import { AcceptInvitesComponent } from './pages/dashboard/accept-invites/accept-invites.component';
import { InstanceModel } from './_models/instance.model';
import { AuthService } from './services/auth.service';
import TemplateConfigs from '../customization/template-configs';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'body[root]',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {

    private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
    instance;
    isBrandSpotAdmin$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private translationService: TranslateService,
        private splashScreenService: SplashScreenService,
        private router: Router,
        private tableService: TableExtendedService,
        private instancesService: InstancesService,
        public dialog: MatDialog,
        private invitesService: InvitesService,
        private authService: AuthService,
        private templateConfigs: TemplateConfigs
    ) {
        this.instancesService.getActiveInstance();
        this.unsubscribe.push(
            this.instancesService.instance$.subscribe((r) => {
                if (!r || !this.instance || r.uuid !== this.instance.uuid) {
                    this.instance = r;
                }
            })
        );
        this.unsubscribe.push(
            this.authService.currentUserSubject.subscribe(resp => {
                if (resp && resp.role === 'brandspot-admin') {
                    this.isBrandSpotAdmin$.next(true);
                }
            })
        );
        document.title = this.templateConfigs.projectTitle;

        // default lang
        this.translationService.setDefaultLang('en');
        this.translationService.use('en');

        // set lang
        this.authService.currentUserSubject.subscribe(user => {
            if (user) {
                this.translationService.setDefaultLang(user.language);
                this.translationService.use(user.language);
            }
        });
    }

    ngOnInit() {
        const routerSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                // clear filtration paginations and others
                this.tableService.setDefaults();
                setTimeout(() => {
                    // hide splash screen
                    this.splashScreenService.hide();
                    // scroll to top on every route change
                    window.scrollTo(0, 0);
                    // to display back the body content
                    document.body.classList.add('page-loaded');
                }, 750);
            }
        });
        this.unsubscribe.push(routerSubscription);

        this.unsubscribe.push(
            this.instancesService.addPopup$.subscribe(r => {
                if (r) {
                    this.showAccountCreation();
                }
            })
        );
        this.unsubscribe.push(
            this.invitesService.addPopupInvites$.subscribe(i => {
                if (i) {
                    this.showInvitesAcceptation();
                }
            })
        );
    }

    ngOnDestroy() {
        this.unsubscribe.forEach((sb) => sb.unsubscribe());
    }

    showAccountCreation() {
        const dialogRef = this.dialog.open(AccountCreationComponent, {
            maxWidth: '100%',
            width: '100%',
            height: '100%',
        });

        this.unsubscribe.push(
            dialogRef.afterClosed().subscribe(result => {
                setTimeout(() => {
                    if (!this.instancesService.instance$.value) {
                        this.showAccountCreation();
                    }
                }, 500);
            })
        );
    }

    showInvitesAcceptation() {
        this.invitesService.list({}).subscribe((r: any) => {
            if (r.length > 0) {
                const dialogRef = this.dialog.open(AcceptInvitesComponent, {
                    maxWidth: '100% !important',
                    width: '100%',
                    height: '100%',
                    data: {
                        invites: r,
                    }
                });
                this.unsubscribe.push(
                    dialogRef.afterClosed().subscribe(result => {
                        if (!this.instance) {
                            this.checkNoInstances();
                        }
                    })
                );
            } else {
                if (!this.instance) {
                    this.checkNoInstances();
                }
            }
        });
    }

    checkNoInstances() {
        this.instancesService.list({}).subscribe((r) => {
            if (r.total === 0) {
                this.instancesService.clearInstance();
                this.unsubscribe.push(
                    this.isBrandSpotAdmin$.subscribe(adm => {
                        if (!adm) {
                            this.instancesService.addPopup$.next(true);
                        }
                    })
                );
            } else {
                let selected = false;
                r.data.forEach((inst: InstanceModel) => {
                    if (this.instance && inst.uuid === this.instance.uuid && !this.instance.isActive) {
                        selected = true;
                        this.instancesService.setActiveInstance(new InstanceModel());
                        this.instancesService.setActiveInstance(new InstanceModel(inst));
                        return;
                    } else if (this.instance && inst.uuid === this.instance.uuid && this.instance.isActive) {
                        selected = true;
                        this.instancesService.setActiveInstance(new InstanceModel(inst));
                    }
                });
                if (!selected) {
                    this.instancesService.setActiveInstance(new InstanceModel(r.data[0]));
                }
            }
        });
    }
}
