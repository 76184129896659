import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Component({
    selector: 'app-select-field-v',
    templateUrl: './select-field-v.component.html',
    styleUrls: ['./select-field-v.component.scss'],
})
export class SelectFieldVComponent implements OnInit, OnChanges {

    @ViewChild('selectOpts') selectOpts;

    @Input() selected: any;
    @Output() selectedChange = new EventEmitter<any>();
    @Input() options;
    loading$ = new BehaviorSubject(false);
    @Input() showWhite;
    indexOpt;

    constructor() {
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.options && changes.options.currentValue || changes.selected && changes.selected.currentValue) {
            if (this.options?.length > 0) {
                this.setOption(this.options, this.selected);
                this.loading$.next(true);
            }
        }
    }

    setOption(opts, selected) {
        if (selected && selected.uuid !== '') {
            opts.map((op, index) => {
                if (selected.uuid === op.uuid) {
                    this.indexOpt = index;
                }
            });
        } else {
            this.indexOpt = '';
        }
    }

    catchOption(event) {
        setTimeout(() => {
            this.selectedChange.emit(event);
        }, 500);
    }

}
