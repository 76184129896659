import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {InstancesService} from '../../../../services/instances.service';
import {Subscription} from 'rxjs';
import TemplateConfigs from '../../../../../customization/template-configs';

@Component({
    selector: 'app-table-list',
    templateUrl: './table-list.component.html',
    styleUrls: ['./table-list.component.scss']
})
export class TableListComponent implements OnInit {

    @Input() fields: any[] = [];
    @Output() fieldsChange = new EventEmitter<any>();
    @Input() values: any[] = [];
    @Input() tHeadWhite;
    @Input() errorMsg;
    @Input() imgPath;
    @Input() tableErrorMsg: any;
    @Input() actionCreate;
    @Output() actionCreateChange = new EventEmitter<any>();
    @Input() autoHeight;
    instance;
    _subscriptions: Subscription[] = [];
    projectName;

    constructor(
        private instancesService: InstancesService,
        private templateConfigs: TemplateConfigs,
    ) {
        this._subscriptions.push(
            this.instancesService.instance$.subscribe((r) => {
                if (!r || !this.instance || r.uuid !== this.instance.uuid) {
                    this.instance = r;
                }
            })
        );
        this.projectName = this.templateConfigs.projectName;
    }

    ngOnInit(): void {
    }

    emitCreate() {
        this.actionCreate = true;
        this.actionCreateChange.emit(true);
    }

    changeArrow(label) {
        if (label !== 'Budget') {
            this.fields.forEach(el => {
                if (el.label === label && !el.showArrow && !el.arrowTop) {
                    el.showArrow = true;
                    el.arrowTop = false;
                } else if (el.label === label && el.showArrow && !el.arrowTop) {
                    el.showArrow = true;
                    el.arrowTop = true;
                } else if (el.label === label && el.showArrow && el.arrowTop) {
                    el.showArrow = null;
                    el.arrowTop = null;
                }
                if (el.label !== label) {
                    el.showArrow = null;
                    el.arrowTop = null;
                }
            });
            this.fieldsChange.emit(this.fields);
        }
    }

}
