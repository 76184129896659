import { Injectable } from "@angular/core";
import { Subscription } from "rxjs";
import { InstancesService } from "../services/instances.service";
import { PermissionAction } from "./enums/permission-action";
import { UserPermissionType } from "./enums/user-permission-type";

@Injectable({
    providedIn: 'root'
})
export class UserPermissionsService {
    private _subs: Subscription[] = [];
    private instance = null;

    constructor(
        private instancesService: InstancesService
    ) {
        this._subs.push(
            this.instancesService.instance$.subscribe((r) => {
                if (r && (!this.instance || r.uuid !== this.instance.uuid)) {
                    this.instance = r;
                }
            })
        );
    }

    private getUserRole() {
        if (!this.instance || !this.instance.clientUsers || this.instance.clientUsers.length === 0) {
            return null;
        }
        return this.instance.clientUsers[0]?.role;
    }

    private hasPermission(resourceName: UserPermissionType, action: string): boolean {
        const userRole = this.getUserRole();

        if (!userRole || !Array.isArray(userRole.grantList)) {
            return false;
        }

        const resourceGrant = userRole.grantList.find(grant => grant.resource === resourceName && grant.action?.includes(action));

        return !!resourceGrant;
    }

    canCreate(resourceName: UserPermissionType): boolean {
        return this.hasPermission(resourceName, PermissionAction.Create);
    }

    canRead(resourceName: UserPermissionType): boolean {
        return this.hasPermission(resourceName, PermissionAction.Read);
    }

    canUpdate(resourceName: UserPermissionType): boolean {
        return this.hasPermission(resourceName, PermissionAction.Update);
    }

    canDelete(resourceName: UserPermissionType): boolean {
        return this.hasPermission(resourceName, PermissionAction.Delete);
    }
}
