<div *ngIf="formGroup" class="form mt-4 fv-plugins-bootstrap fv-plugins-framework" [formGroup]="formGroup">

    <div class="row">

        <!-- Name -->
        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-4">
            <label translate>Name
                <span class="text-primary"
                      *ngIf="formGroup.get('name') && formGroup.get('name').errors">*</span>
            </label>
            <input type="text" class="form-control form-control-solid rounded form-control-lg"
                   [ngClass]="{ 'border-danger adn-input-border-error' : showCompanyNameError}"
                   (change)="changesControl()"
                   name="name" [placeholder]="'Company name' | translate" formControlName="name">
        </div>

        <!-- Legal name -->
        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-4">
            <div class="form-group fv-plugins-icon-container">
                <label translate>Legal name
                    <span
                            class="text-primary"
                            *ngIf="formGroup.get('legalName') && formGroup.get('legalName').errors"
                    >*</span>
                </label>
                <input type="text" class="form-control form-control-solid rounded form-control-lg"
                       [ngClass]="{ 'border-danger adn-input-border-error' : showCompanyLegalNameError}"
                       (change)="changesControl()"
                       name="name" [placeholder]="'Company legal name' | translate" formControlName="legalName">
            </div>
        </div>

        <!-- VAT -->
        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-4">
            <div class="d-flex justify-content-between align-content-between">
                <label translate>VAT
                    <span class="text-primary"
                          *ngIf="formGroup.get('vat').errors"
                    >*</span>
                </label>
                <span [inlineSVG]="'./assets/media/svg/icons/Code/Question-circle.svg'"
                      cacheSVG="true"
                      class="svg-icon svg-icon-sm svg-icon-primary cursor-pointer"
                      [ngbPopover]="'VAT number stands for value added tax identification number. Having a VAT number means that a business will add a VAT price to the products or services that they sell. Businesses are then able to get back the VAT that they paid when buying their goods. The country standard code GB is followed by 9 digits (block of 3, block of 4, block of 2 – e.g. GB999 9999 73). To branch traders is followed by 12 digits (as for 9 digits, followed by a block of 3 digits).' | translate"
                      placement="bottom"
                      triggers="mouseenter:mouseleave" popoverTitle="{{ 'Value added tax (VAT)' | translate }}">
                </span>
            </div>
            <input type="text" class="form-control form-control-solid rounded form-control-lg"
                   [ngClass]="{ 'border-danger adn-input-border-error' : showCompanyVATError}"
                   (change)="changesControl()"
                   name="vat" [placeholder]="'Must have at least 9 digits' | translate" value="" formControlName="vat">
        </div>

        <!-- Business area -->
        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-4">
            <label translate>Business area
                <span
                        class="text-primary"
                        *ngIf="formGroup.get('businessSector').errors"
                >*</span>
            </label>
            <app-select-field-v
                    [ngClass]="{ 'border-danger adn-input-border-error adn-select-field-bs-adjust rounded' : showCompanyBusinessSectorError}"
                    *ngIf="(userPermissions.canUpdate(userPermissionType.Client) && (businessAreas$ | async)) || creation"
                    [options]="businessAreas$ | async"
                    [selected]="formGroup.controls.businessSector.value" (selectedChange)="catchOptionChange($event)">
            </app-select-field-v>
            <input type="text" class="form-control form-control-solid rounded form-control-lg"
                   [hidden]="creation"
                   *ngIf="!userPermissions.canUpdate(userPermissionType.Client)"
                   name="vat" [placeholder]="'Must have at least 9 digits' | translate"
                   [value]="setBSector(formGroup.controls.businessSector.value)" disabled>
        </div>

        <!-- Website -->
        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-4">
            <label translate>Website
                <span class="text-primary"
                      *ngIf="formGroup.get('website').errors"
                >*</span>
            </label>
            <input type="text" class="form-control form-control-solid rounded form-control-lg"
                   name="country" [placeholder]="'Website link' | translate" value="" formControlName="website">
        </div>

        <!-- Email -->
        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-4">
            <label translate>Email
                <span class="text-primary" *ngIf="formGroup.get('email').errors">*</span>
            </label>
            <input type="email" class="form-control form-control-solid rounded form-control-lg"
                   [ngClass]="{ 'border-danger adn-input-border-error' : showCompanyEmailError}"
                   (change)="changesControl()"
                   name="email" [placeholder]="'Company email' | translate" value="" formControlName="email">
        </div>

        <!-- Address -->
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-4">
            <label translate>Address
                <span class="text-primary"
                      *ngIf="formGroup.get('address') && formGroup.get('address').errors">*</span>
            </label>
            <input type="text" class="form-control form-control-solid rounded form-control-lg"
                   [ngClass]="{ 'border-danger adn-input-border-error' : showCompanyAddressError}"
                   (change)="changesControl()"
                   name="name" [placeholder]="'Neighborhood, street, door number' | translate" formControlName="address">
        </div>

        <!-- Country -->
        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-4">
            <label translate>Country
                <span class="text-primary" *ngIf="formGroup.get('country').errors">*</span>
            </label>
            <input type="text" class="form-control form-control-solid rounded form-control-lg"
                   name="country" [placeholder]="'Country' | translate" value="" formControlName="country">
        </div>

        <!-- City -->
        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-4">
            <label translate>City
                <span class="text-primary" *ngIf="formGroup.get('city').errors">*</span>
            </label>
            <input type="text" class="form-control form-control-solid rounded form-control-lg"
                   name="city" [placeholder]="'City' | translate" value="" formControlName="city">
        </div>

        <!-- Postal Code -->
        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-4">
            <label translate>Postal Code
                <span class="text-primary"
                      *ngIf="formGroup.get('postalCode') && formGroup.get('postalCode').errors">*</span>
            </label>
            <input type="tel" class="form-control form-control-solid rounded form-control-lg"
                   name="tel" [placeholder]="'Postal Code' | translate" value="" formControlName="postalCode">
        </div>

        <!-- Contact -->
        <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-4">
            <label translate>Contact
                <span class="text-primary"
                      *ngIf="formGroup.get('phone') && formGroup.get('phone').errors">*</span>
            </label>
            <input type="tel" class="form-control form-control-solid rounded form-control-lg"
                   name="tel" [placeholder]="'Phone number' | translate" value="" formControlName="phone">
        </div>
    </div>
</div>
