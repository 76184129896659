const keycloakConfig = {
    url: 'https://auth.dreammedia.store/',
    realm: 'dreammedia',
    clientId: 'spotside-ads',
    adminRole: 'brandspot-admin',
    credentials: {
        secret: 'eGHvwa0QtfiWkoJ4qUAEpzxfPQRdglaR'
    }
};

export const environment = {
    production: true,
    appVersion: '2.0',
    USERDATA_KEY: 'authf649fc9a5f55',
    isMockEnabled: false,
    api: 'https://api.dreammedia.store',
    api_central: 'https://api.dreammedia.store',
    auth_api: 'https://api.dreammedia.store',
    keycloak: keycloakConfig,
    project: 'brandspot_panel',
    websocket_server: 'wss://api.dreammedia.store:442',
    mypos_payments_callback: 'https://api.dreammedia.store/payments/mypos',
    sidNumber: '698378',
    walletNumber: '40943344651',
    apiUrl: 'api',
    bestSelection: '65d147d2-17cd-4500-8550-4319e4c7ac37,1a9228a1-c8f8-4240-aae6-3de10b4599bc,0a2a2ae4-638b-4469-848d-8207e3847142,aa4d0b0c-1861-418c-8873-d8c4f1627e67,fb171aab-6cdf-4b63-b3ae-2093c640d8ab,7190cc3c-7ccd-4ee5-a7ad-26e5e9424f7b,175b135e-5ceb-4153-b7ed-125a89a0fd65,a276108c-378e-4211-9374-39e2f8202c25,63518c68-d547-4535-ad83-47babe04272d,39924ce9-53b0-4d1a-ba88-ec1e3c0c2ec4',
    latestEquips: 'b3d5f9a7-be9d-427e-b5b5-d954d738ef2d,8453a04e-188b-4625-992e-79b5fbcbd1ca,a48bd16e-a0d0-48a5-946e-4d5b3cbc968a,3d519081-da8d-4337-8220-28b8103eef2f,a369761a-db87-4abc-9184-a89787d07bf7,954df688-b7ac-4cbc-87da-a693365a84aa,33264a4f-cbe6-47b1-ba74-194a2b4e4a20,e683dd52-7bb6-440f-94d2-d06ed29aa3f7,5b8f78a3-0121-4e80-b179-e9df67c4ce3a,1dbe3c4c-ea7d-4f36-b6bf-6a66dca43aa1',
    hasDspConfigsEnabled: true,
    default_countries: [
        {
            uuid: 1,
            name: 'Portugal'
        }
    ]
};
