<div id="adn-panel" [hidden]="!show"
     [ngClass]="{
         'adn-panel-full' : showFull,
         'adn-panel-simple' : showSimple,
         'adn-panel-discover' : showSimpleDiscover,
         'adn-vertical-panel' : showVerticalFull,
         'adn-v-schedules' : showVerticalSchedules,
         'adn-adv-prices': showVerticalAdvPrices,
         'adn-rented-displays': showRentedDisplays,
         'adn-file-folders': showFileFolders,
         'adn-camp-info-edit': showCampaignInfoEdit,
         'adn-dash-charts': showInDashboardCharts,
         'adn-dash-charts-mini': showInDashboardChartsMini,
         'adn-empty-page': showInEmptyPage,
         'adn-impressions-campaign': showImpressionsCampaign,
         'adn-creation-campaign': showLoadingOnCampaign,
         'adn-show-in-balance' : showInBalance, 'adn-show-in-balance-movements' : showInBalanceMovements}">
    <svg class="splash-spinner" viewBox="0 0 50 50">
        <circle
          class="path"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          stroke-width="5"
        ></circle>
      </svg>
</div>
