// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const keycloakConfig = { // dev
    url: 'https://auth.dreammedia.store/',
    realm: 'dreammedia',
    clientId: 'spotside-ads-staging',
    adminRole: 'brandspot-admin',
    credentials: {
        secret: 'eGHvwa0QtfiWkoJ4qUAEpzxfPQRdglaR'
    }
};

export const environment = {
    production: false,
    appVersion: '2.0',
    USERDATA_KEY: 'authf649fc9a5f55',
    isMockEnabled: false,
    api: 'https://api-staging.brandspot.media',
    api_central: 'https://api-staging.brandspot.media',
    auth_api: 'https://api-staging.brandspot.media',
    keycloak: keycloakConfig,
    project: 'brandspot_panel',
    websocket_server: 'wss://api-staging.mybuyon.com:442',
    mypos_payments_callback: 'https://api-staging.brandspot.media/payments/mypos',
    sidNumber: '698378',
    walletNumber: '40943344651',
    apiUrl: 'api',
    bestSelection: null,
    latestEquips: null,
    hasDspConfigsEnabled: true,
    default_countries: [
        {
            uuid: 1,
            name: 'Portugal'
        }
    ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
