export enum UserPermissionType {
    Equipment = 'equipment',
    Campaign = 'campaign',
    Account = 'account',
    Role = 'role',
    Users = 'users',
    CampaignVersion = 'campaign-version',
    DailyReport = 'daily-report',
    File = 'file',
    Transaction = 'transaction',
    History = 'history',
    Prints = 'prints',
    Invite = 'invite',
    Group = 'group',
    Client = 'client'
}