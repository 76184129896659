import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import TemplateConfigs from '../../../../customization/template-configs';

@Component({
    selector: 'app-accept-invites',
    templateUrl: './accept-invites.component.html',
    styleUrls: ['./accept-invites.component.scss']
})
export class AcceptInvitesComponent implements OnInit {

    values = [];
    getHelpLink = 'https://spotside.com';

    constructor(
        public dialogRef: MatDialogRef<AcceptInvitesComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private templateConfigs: TemplateConfigs
    ) {
        this.values = data.invites;
        this.getHelpLink = templateConfigs.getHelp;
    }

    ngOnInit(): void {
    }

    closeDialog() {
        this.dialogRef.close(true);
    }

}
