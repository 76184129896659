<div mat-dialog class="card card-custom">
    <div class="card-body p-0">

        <!--begin: Wizard -->
        <div #wizard class="wizard wizard-5 d-flex flex-column flex-lg-row flex-row-fluid" id="kt_wizard"
             data-wizard-state="step-first">
            <div class="wizard-nav wizard-aside bg-primary flex-column flex-row-auto w-100 w-lg-300px w-xl-400px w-xxl-500px">
                <div class="d-flex flex-column-fluid flex-column px-xxl-30 px-10">
                    <!-- Account title -->
                    <div class="wizard-nav d-flex d-flex justify-content-center pt-10 pt-lg-20 pb-5">
                        <h1 class="adn-account-title" translate>You have some invites</h1>
                    </div>
                </div>
                <div class="wizard-steps">
                    <a class="wizard-step" href="javascript:;" data-wizard-type="step" data-wizard-state="current">
                    </a>
                </div>
                <div class="adn-invites-backg backgroundd-flex flex-row-auto bgi-no-repeat bgi-position-y-bottom bgi-position-x-center bgi-size-contain pt-2 pt-lg-5 h-700px"></div>
            </div>

            <div class="wizard-content bg-gray-100 d-flex flex-column flex-row-fluid py-15 px-5 px-lg-10">
                <div class="card card-custom card-shadowless rounded-top-0">
                    <div class="card-body p-0">
                        <div class="row justify-content-center py-8 px-8">
                            <div class="col-xl-12 col-xxl-12">

                                <!-- Fixed content at all steps: more info and close dialog -->
                                <div class="d-flex flex-column align-items-end">
                                    <button class="adn-total-rad btn btn-sm btn-icon btn-bg-light btn-icon-primary btn-hover-primary
                                        cursor-pointer px-7 py-7 ml-3" (click)="closeDialog()">
                                        <i class="flaticon2-cross icon-sm"></i>
                                    </button>
                                    <div class="d-flex justify-content-start mt-4">
                                        <span class="font-weight-bold text-muted font-size-sm" translate>Need some help?</span>&nbsp;
                                        <a href="{{ getHelpLink }}" target="_blank"
                                           class="font-weight-bolder text-primary font-size-sm"
                                           translate>
                                            Get Help
                                        </a>
                                    </div>
                                </div>

                                <!--begin: Form Wizard Form-->
                                <form class="form mt-0 mt-lg-10" id="kt_form">

                                    <!--begin: Form Wizard Step 1-->
                                    <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                                        <!-- Form Account Title -->
                                        <div class="adn-card-header card-header border-0 pt-5">
                                            <h3 class="card-title align-items-start flex-column">
                                                <span class="card-label font-weight-bolder text-dark" translate>Invites</span>
                                                <span class="text-muted mt-3 font-weight-bold font-size-sm" translate>You have been invited to collaborate in a new project</span>
                                            </h3>
                                        </div>
                                        <!--end: Form Wizard Step 1-->
                                        <app-invite-accept [values]="values"></app-invite-accept>
                                    </div>
                                </form>
                                <!--end: Form Wizard Form-->
                            </div>
                        </div>
                        <!--end: Wizard -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
